.footer {
  @include no-select;

  padding: var(--spacing-1);

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &__links {
    display: flex;
    gap: var(--spacing-1);
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__links a {
    @include font('meta');
    @include easing(text-decoration-color);
    white-space: nowrap;

    &:not(:hover) {
      text-decoration-color: transparent;
    }
  }
}
