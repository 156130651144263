.newsletter {
  @include no-select;

  background: var(--color-orange);
  margin-inline: var(--spacing-1);
  padding-inline: var(--spacing-3);
  padding-block: var(--spacing-2);
  border-radius: var(--border-radius);

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-1);

  color: var(--color-white);

  @include screen('mobile') {
    flex-direction: column;
    align-items: flex-start;
  }

  &__text {
    @include font('h3');
  }

  &__ctas {
    display: flex;
    gap: var(--spacing-0);
    margin-left: auto;
  }

  .cta {
    --color-text: var(--color-white);
    --color-background: var(--color-orange);
  }
}
