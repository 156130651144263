.filter {
  @include font('meta');
  background: var(--color-gray-10);
  border-radius: var(--border-radius);
  display: flex;

  input, button {
    @include reset-input;
    padding: var(--spacing-1);
  }

  input {
    flex: 1;
    min-width: 0;
    color: var(--color-black);

    &::placeholder {
      @include easing(color);
      color: var(--color-gray-90);
    }

    &:focus::placeholder {
      color: var(--color-gray-50);
    }
  }

  button {
    @include no-select;
    @include easing(opacity);
    cursor: pointer;

    color: var(--color-black);
  }

  input:placeholder-shown + button {
    display: none;
  }
}
