.sidebar {
  @include invisible-scroll(y);
  @include no-select;

  --color-inactive: var(--color-gray-90);

  z-index: 1;
  position: sticky;
  top: calc(var(--menu-height) + var(--spacing-1));
  max-height: calc(100vh - var(--menu-height) - var(--spacing-1) * 2);

  background: var(--color-gray-10);
  border-radius: var(--border-radius);
  padding: var(--spacing-1);
  padding-right: calc(var(--spacing-1) + var(--spacing-0));

  &[data-color='blue'] {
    --color-inactive: rgba(255 255 255 / 30%);
    background: var(--color-blue);
    color: var(--color-white);
  }

  &__header {
    position: relative;
    margin-bottom: var(--spacing-4);
  }

  #toggleSidebar,
  label[for='toggleSidebar'] {
    display: none;
  }

  a {
    @include easing(color);
    text-decoration: none;

    &:hover {
      color: var(--color-black);
    }
  }

  &[data-color='blue'] a:hover {
    color: var(--color-white);
  }

  .filter {
    display: none;
    margin-inline: calc(-1 * var(--spacing-1));
    background: transparent;
    * {
      color: var(--color-white);
    }

    *::placeholder {
      color: var(--color-white);
      opacity: 0.5;
    }
  }

  &__title {
    @include font('h3');
    margin-bottom: var(--spacing-1);
  }

  &__text {
    @include font;
    font-size: 1.4rem;
    line-height: 120%;
    max-width: 32ch;
  }

  &__entries {
    @include no-select;
    margin-bottom: var(--spacing-2);

    &[data-label]::before {
      @include font('meta');
      display: block;
      content: attr(data-label);
      margin-bottom: var(--spacing-1);
    }

  }

  &__entry {
    @include font('sidebar');
    margin-bottom: var(--spacing-0);

    &:not(:has(details)) {
      @include font('meta');
    }
  }

  &__entry.is-active &__tree li:not(.is-active),
  &__entries:has(.is-active):not(:hover) &__entry:not(.is-active):not(:hover) {
    color: var(--color-inactive);
  }

  details[open] summary,
  .is-active h4,
  .is-active > a {
    font-weight: 500;
  }

  &__tree {
    summary {
      @include font('meta');
      cursor: pointer;
      display: block;
    }

    summary::-webkit-details-marker {
      display:none;
    }

    li {
      margin-bottom: var(--spacing-0);
    }

    li:first-of-type {
      margin-top: calc(var(--spacing-0) * 2);
    }

    li:last-child {
      margin-bottom: var(--spacing-2);
    }

    a {
      display: flex;
      gap: 1ch;
    }

    svg {
      // display: none;
      flex-shrink: 0;
      height: 1.8rem;
      width: auto;
      margin-top: -0.2rem;
    }
  }

  // Mobile

  @include screen ('mobile') {
    top: var(--menu-height);
    height: unset;
    max-height: calc(70vh - var(--menu-height));
    margin: calc(-1 * var(--spacing-1));
    margin-bottom: var(--spacing-2);
    border-radius: 0;
    padding-right: var(--spacing-1);

    border-bottom: 1px solid var(--color-gray-50);

    label[for='toggleSidebar'] {
      @include easing(transform);
      @include no-select;
      @include hitbox(var(--spacing-1));
      cursor: pointer;
      display: block;
      position: absolute;
      right: 0;
      top: 0;

      display: flex;
      align-items: center;
      height: 1lh;
    }

    #toggleSidebar:checked + &__header label[for='toggleSidebar'] {
      transform: rotate(180deg);
    }

    #toggleSidebar:not(:checked) + &__header &__text,
    #toggleSidebar:not(:checked) ~ &__entries {
      display: none !important;
    }

    #toggleSidebar:not(:checked) + &__header,
    #toggleSidebar:not(:checked) + &__header &__title {
      margin-bottom: 0;
    }

    &:has(#toggleSidebar:checked) {
      border-bottom: 1px solid var(--color-gray-50);
    }

    &__title {
      @include font('header');
    }

    &__entries[data-label]::before {
      @include font('header');
    }
  }
}
