.category {
  @include no-select;
  @include font('meta');
  display: flex;
  align-items: center;
  gap: 1ch;

  &__icon svg {
    height: 1.8rem;
    width: auto;
  }

  &__icon:empty {
    display: none;
  }
}
