.card--poster {
  display: block;
  position: relative;

  &:hover &__infos {
    opacity: 1;
  }

  &__infos {
    @include easing(opacity);
    @include no-select;
    pointer-events: none;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    color: var(--color-white);
    display: grid;
    place-items: center;
    opacity: 0;
  }
}
