.card--article {
  &__title {
    @include font('h2');
  }

  &.is-compact &__title {
    @include font('h3');
  }

  &__excerpt {
    overflow: hidden;
    text-overflow: ellipsis;

    max-height: calc(3lh);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;

    max-width: 80ch;

    &:empty {
      display: none;
    }
  }

  &__metas {
    @include font('meta');

    display: flex;
    flex-wrap: wrap;
    color: var(--color-metas);
  }

  &__meta {
    white-space: nowrap;

    &:has(+ &:not(:empty))::after {
      content: ',\00A0';
    }
  }
}
