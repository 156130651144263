.cta {
  @include reset-input;
  @include no-select;
  @include easing((color, background-color));
  @include font('meta');

  // Using SASS vars to limite CSS vars scope and allow fallbacks
  $color-text: var(--color-text, var(--color-black));
  $color-background: var(--color-background, var(--color-white));

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-inline: 1.4rem;
  height: var(--cta-height);
  white-space: nowrap;

  border: 1px solid $color-text;
  border-radius: 3.2rem;
  color: $color-text;
  background: $color-background;

  &.is-active,
  &:focus,
  &:hover {
    color: $color-background;
    background: $color-text;
  }

  &:focus:not(.is-active),
  &:active:not(.is-active),
  &:hover:not(.is-active) {
    @include screen('mobile') {
      color: $color-text;
      background: $color-background;
    }
  }

  &[disabled],
  &.is-disabled {
    opacity: 0.3;
    color: $color-text;
    background: $color-background;
  }

  &:is(a) {
    text-decoration: none;
  }

  svg {
    height: 1.8rem;
    width: auto;
    margin-right: 1ch;
  }
}
