.members {
  --columns: 4;

  @include screen('mobile') {
    --columns: 2 !important;
  }

  &__header {
    position: sticky;
    top: var(--menu-height);
    background: var(--color-white);
    padding-block: var(--spacing-1);
    margin-top: calc(-1 * var(--spacing-1));

    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);

    @include screen('mobile') {
      padding: 0;
      margin: calc(-1 * var(--spacing-1));
      margin-bottom: var(--spacing-1);
    }
  }

  &__index {
    @include font('meta');
    background: var(--color-gray-10);
    border-radius: var(--border-radius);
    display: flex;

    // Compensate child width so that the letter boundaries visually match spacing-1
    padding-inline: calc(var(--spacing-1) - ((100% / 26) - 1ch) / 2);

    a {
      @include easing(color);

      flex: 1;
      padding-block: var(--spacing-1);
      text-align: center;
      text-decoration: none;
    }

    &:has(a:hover) a:not(:hover) {
      color: var(--color-gray-50);
    }
  }

  &__entries {
    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    gap: var(--spacing-1);
  }
}
