body[data-template='home'] {
  .card--sca {
    .card__text {
      @include font;
      line-height: 130%;
      margin-bottom: var(--spacing-2);
    }

    .card__footer {
      margin-bottom: var(--spacing-2);

      .cta {
        font-size: 1.6rem;
        height: 3.4rem;
      }
    }
  }

  .card--sca-video {
    position: relative;
    background: var(--color-black);
    outline: 0;
    flex: 1;

    @include screen ('mobile') {
      min-height: 50rem;
    }

    iframe {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      padding: var(--spacing-1);
    }
  }

  .form {
    background: var(--color-gray-10);
    outline: 0;
    overflow: hidden;

    &__title {
      @include font('h1');
    }

    &__cover {
      flex: 1;
      position: relative;
      overflow: hidden;
      margin-top: var(--spacing-1);
      margin-inline: calc(-1 * var(--spacing-2));
      margin-bottom: calc(-1 * var(--spacing-2));

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include screen('mobile') {
        display: none;
      }
    }
  }

  .newsletter {
    margin-top: var(--spacing-4);
  }

  [data-layout='col-2'] section {
    // Fix Safari glitch on home annuaire section
    height: fit-content;
  }
}
