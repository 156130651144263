.banner {
  position: relative;
  height: 20rem;
  width: 100vw;
  left: calc(-50vw + 50%);

  img {
    @include no-drag;
  }

  @include screen('mobile') {
    height: 13rem;
  }
}
