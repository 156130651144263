.cards {
  --paginate: 1;

  overflow: hidden;

  @include screen('mobile') {
    --paginate: 1 !important;
  }

  &[data-paginate='0'] &__container {
    flex-direction: column;
  }

  &[data-paginate='2'] { --paginate: 2; }
  &[data-paginate='3'] { --paginate: 3; }
  &[data-paginate='4'] { --paginate: 4; }
  &[data-paginate='5'] { --paginate: 5; }
  &[data-paginate='6'] { --paginate: 6; }


  &[data-color='blue'] .section-header {
    --color-text: var(--color-blue);
    --color-background: var(--color-white);
  }

  &[data-color='red'] .section-header {
    --color-text: var(--color-red);
    --color-background: var(--color-white);
  }

  &__container {
    display: flex;
    gap: var(--spacing-1);
  }

  &__dot.cta {
    min-width: fit-content;
    width: var(--cta-height);
    height: var(--cta-height);
    padding-inline: unset;
    font-variant-numeric: tabular-nums;
  }

  .section-header .cta:not(&__dot) {
    @include screen('mobile') {
      display: none;
      @include debug;
    }
  }

  .card {
    --width: calc((100% - var(--spacing-1) * (var(--paginate) - 1)) / var(--paginate));
    flex: 0 0 var(--width);
    min-width: 0;
  }

  .card + .card:last-child {
    margin-right: var(--spacing-1);
  }

  // .flickity-viewport {
  //   @include easing(height);
  //   // min-height: 30rem;
  // }

  // // .flickity-viewport
  // // .flickity-slider,
  // .flickity-cell {
  //   min-height: fit-content;
  //   // height: 100%;
  // }

  // &.is-ready .flickity-cell {
  //   height: inherit;
  // }

  // ???
  // .flickity {
  //   &-button {
  //     @include reset-input;
  //     background: var(--color-white);
  //     outline: 1px solid var(--color-gray-50);

  //     &:active {
  //       opacity: 1;
  //       background: var(--color-gray-10);
  //       outline: 1px solid var(--color-black);
  //     }

  //     &:focus {
  //       outline: 1px solid var(--color-gray-50);
  //       box-shadow: none;
  //     }
  //   }

  //   &-prev-next-button {
  //     width: var(--spacing-1);
  //     height: var(--spacing-1);
  //     padding: var(--spacing-1);
  //     border-radius: 100%;
  //   }

  //   &-button-icon {
  //     $size: 1.5rem;

  //     left: calc(var(--spacing-1) - #{$size / 2});
  //     top: calc(var(--spacing-1) - #{$size / 2});
  //     width: $size;
  //     height: $size;
  //     fill: var(--color-black);
  //   }

  //   &-prev-next-button.previous { left: calc(-1 * var(--spacing-1) * 0.5); }
  //   &-prev-next-button.next { right: calc(-1 * var(--spacing-1) * 0.5); }
  // }
}
