.prose {
  @include font;
}

.prose > {
  h2,
  h3,
  h4,
  h5,
  h6,
  ul, ul ul,
  ul li,
  details,
  p {
    margin-bottom: var(--spacing-1);
  }

  h1,
  h2 {
    @include font('h2');
    margin-top: 1em;
  }
  h3 { @include font('h3') }
  h4 { @include font('h3'); }

  ul, ul ul {
    list-style: disc outside;
    padding-left: 1em
  }

  figure {
    margin-block: var(--spacing-2);

    iframe {
      width: 100%;
      aspect-ratio: 16/9;
    }

    &[data-float] {
      float: left;
      width: 33.333%;
      margin-block: 0;
      margin-bottom: 0.25em;
      margin-right: var(--spacing-1);
    }

    figcaption {
      color: var(--color-gray-90);
      margin-top: var(--spacing-1);
      text-align: center;
    }

    img {
      margin: 0 auto;
      width: auto;
      max-width: 100%;
      max-height: 70vh;
    }
  }

  blockquote {
    font-size: 125%;
    line-height: 130%;
    padding-left: var(--spacing-1);
    margin-block: var(--spacing-2);
    border-left: 4px solid var(--color-gray-10);

    footer {
      @include font;
      color: var(--color-gray-90);
      margin-top: var(--spacing-1);
      text-align: right;
      font-style: italic;
    }
  }

  .chapo {
    font-size: 135%;
    line-height: 136%;
    font-weight: 500;
    margin-block: var(--spacing-2);

    &:first-child {
      margin-top: 0;
    }
  }

  // Target Kirby gallery block
  figure[data-ratio] ul {
    display: grid;
    gap: var(--spacing-1);
    margin-block: var(--spacing-2);
    grid-template-columns: repeat(4, 1fr);

    img {
      width: 100%;
      max-height: 10rem;
      object-fit: contain;
    }
  }

  .interview {
    font-size: 110%;
    line-height: 135%;
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-1);
    font-weight: 500;
  }

  hr {
    margin-block: var(--spacing-4);
    border: 0;
    border-top: 1px solid var(--color-gray-50);
  }
}
