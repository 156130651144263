.form {
  @include debug;

  border-radius: var(--border-radius);
  outline: 1px solid var(--color-gray-50);
  outline-offset: -1px;

  color: var(--color-text);
  background-color: var(--color-background);

  padding: var(--spacing-3);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);

  &:has(&__error) {
    outline-color: var(--color-red);
  }

  &__title {
    @include font('h3');
    margin-bottom: var(--spacing-1);
  }

  fieldset {
    display: flex;
    flex-direction: column;

    label {
      @include no-select;
      @include font('meta');
      cursor: pointer;
      margin-bottom: var(--spacing-0);
    }

    &.has-error label {
      color: var(--color-red);
    }

    &.is-required label::after {
      content: '\00a0*';
    }

    input, textarea {
      @include easing(border-bottom-color);
      @include reset-input;
      border-bottom: 1px solid var(--color-gray-50);
      padding-bottom: var(--spacing-0);
      height: fit-content;

      &:focus {
        border-bottom-color: var(--color-black);
      }

      &:valid {
        border-bottom-color: var(--color-blue);
      }

      &::placeholder {
        color: var(--color-gray-50);
      }
    }

    &.has-error input,
    &.has-error textarea {
      border-bottom-color: var(--color-red);
    }

    &.has-error label {
      color: var(--color-red);
    }
  }

  // This is a honeypot
  .fieldset--website {
    position: absolute;
    left: -9999px;
  }

  &:has(fieldset.is-required) fieldset:last-of-type::after {
    @include font('meta');
    content: '* champ obligatoire';
    margin-top: var(--spacing-1);
    color: var(--color-gray-90);
  }

  fieldset:last-of-type {
    margin-bottom: var(--spacing-4);
  }

  &__footer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing-1);
  }

  &__error {
    @include font('meta');
    color: var(--color-red);
    margin-top: var(--spacing-0);
  }

  &__success {
    color: var(--color-blue);
  }
}
