.member {
  &__header {
    margin-bottom: var(--spacing-1);
  }

  &__name {
    @include font('h2');
    margin-bottom: var(--spacing-1);
  }

  &__header--content {
    display: grid;
    grid-template-columns: 30rem 1fr;
    gap: var(--spacing-1);
    overflow: hidden;

    @include screen('mobile') {
      grid-template-columns: unset;
    }
  }

  &__header figure img {
    border-radius: var(--border-radius);
    aspect-ratio: 1;
  }

  h4 {
    @include font('meta');
    color: var(--color-blue);
  }

  &__metas {
    overflow: hidden;

    h4:not(:first-child) {
      margin-top: var(--spacing-1);
    }

    a {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
      text-overflow: ellipsis;
    }
  }

  &__content {
    margin-top: var(--spacing-3);
    padding-top: var(--spacing-2);
    border-top: 1px solid var(--color-gray-10);

    h3 {
      @include font('h2');
    }

    li + li {
      margin-top: var(--spacing-1);
    }

    h4 {
      margin-top: var(--spacing-3);
      margin-bottom: var(--spacing-1);
    }
  }

  &__movie {
    display: flex;
    gap: var(--spacing-1);

    & + & {
      margin-top: var(--spacing-2);
    }

    h4 {
      margin-top: var(--spacing-1);
      margin-bottom: 0;
    }

    h5 {
      @include font('h3');
      margin-bottom: var(--spacing-0);
    }

    figure {
      img {
        width: 20rem;
        height: auto;
        border-radius: var(--border-radius);

        @include screen('mobile') {
          width: 33vw;
        }
      }
    }


    &:not(:has(figure))::before {
      content: '';
      width: 20rem;
      background: var(--color-gray-10);
      border-radius: var(--border-radius);
    }
  }
}
