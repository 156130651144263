body[data-template='newsletters'] {
  // Code injected by mailchimp
  .display_archive {
    @include cols(4, 9);

    .campaign {
      @include font('meta');

      border-radius: var(--border-radius);
      margin-bottom: var(--spacing-1);
      padding: var(--spacing-3);

      color: var(--color-text);
      background-color: var(--color-gray-10);

      a {
        @include font('h2');
        display: block;
      }
    }
  }
}
