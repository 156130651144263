html {
  font-size: 62.5%;
  box-sizing: border-box;
}

*,
*::before,
*::after { box-sizing: inherit; }

body {
  @include font;
  font-family: 'Chivo', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  min-height: 100vh;
  background: var(--color-white);
  color: var(--color-black);

  max-width: $max-width;
  margin: 0 auto;

  overflow-x: hidden;
}

mark,
::selection  {
  background: var(--color-orange);
  color: var(--color-white);
  text-shadow: none;
}

a {
  @include easing(text-decoration-color);
  color: inherit;
  text-underline-offset: inherit;
  text-decoration-thickness: inherit;
}

a, label {
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

img, canvas, svg, iframe, object, video {
  @include no-select;

  display: block;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

img {
  width: 100%;
  height: auto;
}

figure img {
  object-position: var(--focus-x, 50%) var(--focus-y, 50%);
  object-fit: cover;
  height: 100%;
  width: 100%;
}

div.flexgroup {
  display: flex;
  flex-direction: inherit;
  gap: inherit;
  justify-content: inherit;
  align-items: inherit;
}

main {
  padding: var(--spacing-1);
}

.anchor {
  display: block;
  position: relative;
  top: calc(-1 * (var(--menu-height) + var(--spacing-1)));
  visibility: hidden;
}

[data-layout='wall'] {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

[data-layout='sidebar'] {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  gap: var(--spacing-1);

  & > .sidebar { @include cols(1, 3); }
  & > .sidebar + * { @include cols(4, 9); }

  @include screen('mobile') {
    display: block;
  }
}

[data-layout='col-2'] {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch;
  gap: 0 var(--spacing-1);

  @include screen('mobile') {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);

    & > .section-header {
      margin-bottom: 0;
    }
  }

  & > .section-header {
    margin-top: 0;
    grid-column: span 2;
  }
}
