body[data-template='contact'] {
  main a {
    text-decoration-color: currentColor !important;
  }

  .card--contact h2 {
    @include font('h3');
    margin-bottom: var(--spacing-1);
  }

  .card--contact section + section {
    margin-top: var(--spacing-3);
  }

  .card--contact section[data-label]::before {
    @include font('meta');
    display: block;
    content: attr(data-label);
    margin-bottom: var(--spacing-0);
  }

  .card--contact section a {
    word-break: break-all;
  }

  .card--contact .socials {
    flex-wrap: wrap;
    display: flex;
    gap: var(--spacing-1);

    svg {
      width: 2em;
      height: 100%;
    }

    &::before {
      width: 100%;
    }
  }
}
