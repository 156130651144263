.card {
  --color-text: var(--color-black);
  --color-metas: var(--color-blue);
  --color-background: var(--color-white);

  display: grid;

  border-radius: var(--border-radius);
  outline: 1px solid var(--color-gray-50);
  outline-offset: -1px;

  color: var(--color-text);
  background-color: var(--color-background);

  overflow: hidden;

  @include screen('mobile') {
    gap: 0;
  }

  &.has-figure {
    grid-template-columns: repeat(2, 1fr);

    @include screen('mobile') {
      grid-template-columns: unset;
      grid-template-rows: 1fr auto;
    }
  }

  &[data-color='blue'] {
    --color-text: var(--color-white);
    --color-metas: var(--color-white);
    --color-background: var(--color-blue);
    outline: none;
  }

  &[data-color='red'] {
    --color-text: var(--color-white);
    --color-metas: var(--color-white);
    --color-background: var(--color-red);
    outline: none;
  }

  &[data-color='gray'] {
    --color-text: var(--color-black);
    --color-metas: var(--color-blue);
    --color-background: var(--color-gray-10);
    outline: none;
  }

  a {
    @include screen('mobile') {
      text-decoration-color: transparent;
    }

    &:not(:hover) {
      text-decoration-color: transparent;
    }
  }

  &__content {
    padding: var(--spacing-3);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
  }

  &__text {
    &:empty {
      display: none;
    }
  }

  &__footer {
    @include no-select;

    margin-top: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing-3);

  }

  &__ctas {
    display: flex;
    gap: var(--spacing-1);
    margin-left: auto;
    flex-wrap: wrap;

    @include screen('mobile') {
      justify-content: center;
    }
  }

  &__image {
    display: block;
    max-height: 50vh;
    overflow: hidden;

    @include screen('mobile') {
      order: -1;
      max-height: 40vh;
    }

    figure {
      height: 100%;
      background: none !important;
    }

    img {
      @include no-drag;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
      padding: var(--spacing-1);
    }
  }

  // :-(
  // &__image {
  //   display: block;
  //   max-height: 50vh;
  //   background: var(--color-black);
  //   overflow: hidden;

  //   @include screen('mobile') {
  //     order: -1;
  //     max-height: 40vh;
  //   }

  //   figure {
  //     height: 100%;

  //     background-repeat: no-repeat;
  //     background-position: center;
  //     background-size: 100%;
  //   }

  //   img {
  //     @include no-drag;
  //     width: 100%;
  //     height: 100%;
  //     object-fit: contain;
  //     object-position: center center;
  //     padding: var(--spacing-1);
  //   }
  // }
}
