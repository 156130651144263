.menu {
  @include no-select;

  position: sticky;
  z-index: 2;
  top: 0;
  width: 100%;
  max-width: $max-width;

  padding: var(--spacing-0) var(--spacing-1);
  color: var(--color-black);
  background: var(--color-white);

  &::before {
    content: '';
    top: 0;
    bottom: 0;
    left: calc(-50vw + 50%);
    position: absolute;
    background: inherit;
    width: 100vw;
  }

  label[for='toggleMenu'] {
    display: none;
  }

  &__links {
    flex: 1;
    position: relative;
    align-self: stretch;

    display: flex;
    align-items: center;
    gap: var(--spacing-1);
    justify-content: space-between;
  }

  &__link {
    @include easing(color);
    @include font('meta');
    white-space: nowrap;

    a {
      @include easing(text-decoration-color);
    }

    &:not(:hover):not(.is-active) a {
      text-decoration-color: transparent;
    }
  }

  &__link--home {
    flex-shrink: 0;
  }

  &__link--home svg {
    @include no-drag;
    height: 8.0rem;
    width: auto;
  }

  &__link--search {
    margin-right: var(--spacing-1);
  }

  &:not(:hover) &__links:has(.is-active) &__link:not(.is-active):not(:hover) {
    color: var(--color-gray-90);
  }

  label[for=toggleSearch] {
    cursor: pointer;
  }

  &__searchbar {
    position: relative;
    width: 100%;
    border-radius: var(--border-radius);
    background: var(--color-gray-10);

    display: flex;
    align-items: center;


    &:focus-within {
      outline: 1px solid var(--color-gray-50);
      outline-offset: -1px;
    }

    input {
      @include reset-input;
      @include font('meta');
      flex: 1;
      padding: var(--spacing-1);
    }

    .autocomplete__results {
      @include invisible-scroll(y);

      max-height: calc(10 * 2em);
      position: absolute;
      top: calc(100% - var(--border-radius));
      left: 0;
      right: 0;
      background: var(--color-gray-10);
      border: 1px solid var(--color-gray-50);
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      padding-block: calc(var(--spacing-1) / 2);
    }

    .autocomplete__result {
      @include font('meta');

      display: block;
      padding: calc(var(--spacing-1) / 2) var(--spacing-1);

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:not(:hover):not(.is-selected) {
        text-decoration-color: transparent;
      }

      &.is-selected {
        color: var(--color-blue);
      }

      mark {
        color: var(--color-red);
        background: transparent;
      }

      &[data-parent]::before {
        float: right;
        content: attr(data-parent);
        margin-left: auto;
        padding-left: var(--spacing-1);
        color: var(--color-blue);

        @include screen('mobile') {
          content: attr(data-parent)'/';
          float: none;
          padding-left: 0;
          display: block;
        }
      }
    }

    label {
      padding: var(--spacing-1);
    }
  }

  #toggleSearch:checked ~ &__links &__link:not(&__link--home),
  #toggleSearch:not(:checked) ~ &__links &__searchbar {
    display: none;
  }

  // Mobile

  @include screen('mobile') {
    &:has(#toggleMenu:checked) {
      position: fixed;
      top: 0;
      bottom: 0;
    }

    &__link--search,
    &:has(input:focus) &__link:not(&__link--home) {
      display: none;
    }

    label[for='toggleSearch'],
    #toggleMenu:not(:checked) + &__links &__link:not(&__link--home),
    #toggleMenu:not(:checked) + &__links &__searchbar {
      display: none !important;
    }

    &__links {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 100%;
      gap: var(--spacing-3);
    }

    &__link--home {
      margin-right: auto;
      order: -1;
    }

    &__link {
      @include font('h2');
    }

    &__link--home svg {
      height: 6rem;
    }

    &__searchbar {
      order: -1;
      display: flex !important;
      margin-block: var(--spacing-2);
      color: var(--color-black);
    }

    label[for=toggleMenu] {
      @include easing(transform);
      @include hitbox(var(--spacing-1));
      position: absolute;
      right: 0;
      top: 2.1rem;
      display: block;
      cursor: pointer;
    }

    #toggleMenu:checked + &__links label[for=toggleMenu] {
      transform: rotate(-90deg);
    }
  }
}
