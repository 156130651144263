.article {
  height: fit-content;

  & + & {
    margin-top: var(--spacing-5);
  }

  &:last-child {
    padding-bottom: var(--spacing-5);
  }

  &__header {
    display: flex;
    flex-direction: column;
    max-width: 80%;
  }

  &__attachment {
    width: fit-content;
    margin-bottom: var(--spacing-1);
  }

  .category {
    margin-bottom: var(--spacing-1);
  }

  &__title {
    @include font('h1');
    margin-bottom: var(--spacing-4);
  }

  &__metas {
    @include font('meta');

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: var(--color-blue);
    margin-bottom: var(--spacing-1);
  }

  &__meta {
    &:has(+ &:not(:empty))::after {
      content: ',\00A0';
    }
  }

  &__cover {
    margin-bottom: var(--spacing-2);

    img {
      width: auto;
      max-width: 75ch;
      max-height: 70vh;
    }
  }

  &__content {
    margin-right: var(--spacing-2);
    max-width: 75ch;

    @include screen('mobile') {
      max-width: 100%;
      margin-right: 0;
    }
  }
}
