@import '~@tarekraafat/autocomplete.js/dist/css/autoComplete.02.css';

@import 'sass/reset';
@import 'sass/helpers';

$max-width: 1440px;
$breakpoints: (
  'large': ($max-width, 9999px),
  'medium': (1080px, $max-width),
  'small': (800px, 1080px),
  'mobile': (0px, 800px)
);

:root {
  --color-white: #FFFFFF;
  --color-gray-10: #F0F0F2;
  --color-gray-50: #CFCBCC;
  --color-gray-90: #7E7577;
  --color-black: #030304;
  --color-red: #CC2227;
  --color-blue: #0D6C8A;
  --color-orange: #F89B2C;

  --spacing-0: 0.7rem;
  --spacing-1: 2rem;
  --spacing-2: 3.2rem;
  --spacing-3: 4rem;
  --spacing-4: 5rem;
  --spacing-5: 8rem;
  --border-radius: 0.5rem;

  --easing: 200ms;

  --font-size: 1.7rem;
  --font-weight: 400;
  --font-style: normal;
  --line-height: 150%;
  --text-underline-offset: 0.2em;
  --text-decoration-thickness: 1px;

  --cta-height: var(--spacing-2);
  --menu-height: 8.9rem;

  @include font('meta', (
    font-size: 1.4rem,
    line-height: 140%,
    letter-spacing: 0.02em,
    text-transform: uppercase,
    text-underline-offset: 0.2em,
  ));

  @include font('header', (
    font-size: 2.4rem,
    font-weight: 500,
    line-height: 120%,
    letter-spacing: 0.02em,
    text-transform: uppercase,
    text-underline-offset: 0.2em,
  ));

  @include font('h1', (
    font-size: 5.0rem,
    line-height: 102%,
  ));

  @include font('h2', (
    font-size: 3.0rem,
    line-height: 112%,
    text-underline-offset: 0.19em,
    text-decoration-thickness: 2px
  ));

  @include font('h3', (
    font-size: 2.4rem,
    line-height: 105%,
    text-underline-offset: 0.14em
  ));

  @include font('sidebar', (
    font-size: 1.4rem,
    line-height: 130%,
    letter-spacing: 0.01em
  ));

  @include screen('mobile') {
    --spacing-1: 2rem;
    --spacing-2: 2rem;
    --spacing-3: 2rem;

    --font-size: 1.8rem;
    --line-height: 133%;

    --menu-height: 6.3rem;
    --cta-height: 3rem;

    @include font('meta', (
      font-size: 1.2rem
    ));

    @include font('header', (
      font-size: 1.8rem
    ));

    @include font('h1', (
     font-size: 3.2rem
    ));

    @include font('h2', (
      font-size: 2.6rem,
    ));

    @include font('h3', (
      font-size: 2.2rem,
      line-height: 110%
    ));
  }
}

@import 'sass/base';
@import 'sass/prose';

@import 'components/Article';
@import 'components/Banner';
@import 'components/Card/Article';
@import 'components/Card/Card';
@import 'components/Card/Member';
@import 'components/Card/Poster';
@import 'components/Cards';
@import 'components/Category';
@import 'components/Cta';
@import 'components/Filter';
@import 'components/Footer';
@import 'components/Form';
@import 'components/Member';
@import 'components/Members';
@import 'components/Menu';
@import 'components/Newsletter';
@import 'components/SectionHeader';
@import 'components/Sidebar';

@import 'templates/article';
@import 'templates/article-toc';
@import 'templates/contact';
@import 'templates/folders';
@import 'templates/home';
@import 'templates/member';
@import 'templates/members';
@import 'templates/newsletters';
