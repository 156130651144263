.section-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-0);

  margin-bottom: var(--spacing-1);
  min-height: var(--cta-height);

  &__label {
    @include font('header');
    @include no-select;
    margin-right: auto;

    a:not(:hover) {
      text-decoration-color: transparent;
    }
  }

  &__ctas {
    flex: 1;
    display: flex;
    gap: var(--spacing-0);
    justify-content: flex-end;
  }
}
