@mixin cols ($index: 1, $width: var(--grid-columns)) {
  @if $index == 0 { @error 'Grid index starts at 1'; }
  grid-column: col-start #{$index} / span #{$width};
}

@mixin screen ($key) {
  $breakpoint: map-get($breakpoints, $key);
  $min: nth($breakpoint, 1);
  $max: nth($breakpoint, 2);
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin screens ($keys) {
  @each $key in $keys {
    @include screen ($key) {
      @content;
    }
  }
}

@mixin font ($name: '', $props: null) {
  @if $props {
    @each $prop, $value in $props {
      --#{$prop}--#{$name}: #{$value};
    }
  } @else {
    font-size: var(--font-size--#{$name}, var(--font-size, initial));
    font-style: var(--font-style--#{$name}, var(--font-style, initial));
    line-height: var(--line-height--#{$name}, var(--line-height, initial));
    letter-spacing: var(--letter-spacing--#{$name}, var(--letter-spacing, initial));
    font-weight: var(--font-weight--#{$name}, var(--font-weight, initial));
    text-transform: var(--text-transform--#{$name}, var(--text-transform, initial));
    text-underline-offset: var(--text-underline-offset--#{$name}, var(--text-underline-offset, initial));
    text-decoration-thickness: var(--text-decoration-thickness--#{$name}, var(--text-decoration-thickness, initial));
  }
}

@mixin hitbox ($growth, $setPos: false) {
  &::after {
    @include inset(calc(-1 * #{$growth}));
    content: '';
  }

  @if $setPos { position: relative; }
}

@mixin inset ($d: 0, $position: absolute) {
  position: $position;
  top: $d;
  left: $d;
  right: $d;
  bottom: $d;
}

@mixin invisible-scroll ($direction: x) {
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar, ::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
    width: 0;
    height: 0;
    background: transparent;
  }

  @if $direction == x {
    overflow-x: scroll;
    overflow-y: hidden;
  } @else if $direction == y {
    overflow-x: hidden;
    overflow-y: scroll;
  } @else {
    overflow-x: scroll;
    overflow-y: scroll;
  }
}

@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin debug ($offset: 0, $color: red) {
  outline: 1px solid $color;
  outline-offset: $offset;
}

@mixin easing ($property: all, $will-change: false, $duration: var(--easing)) {
  transition-duration: $duration;
  transition-timing-function: cubic-bezier(.215,.61,.355,1);
  transition-property: $property;
  @if $will-change { will-change: $property; }
}

@mixin reset-input {
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
  color: inherit;
  font: inherit;
  line-height: inherit;
  background: transparent;
  border: none;
  appearance: none;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  resize: none;

  &::-moz-focus-inner { padding: 0; border: 0; }
  &:focus { outline: none; }
}

@mixin no-select ($enable: true) {
  @if ($enable) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
  } @else {
    -webkit-touch-callout: initial;
    -webkit-user-select: initial;
    user-select: initial;
  }
}

@mixin no-drag ($enable: true) {
  @if ($enable) {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  } @else {
    user-drag: initial;
    -webkit-user-drag: initial;
    user-select: initial;
    -moz-user-select: initial;
    -webkit-user-select: initial;
    -ms-user-select: initial;
  }
}

@mixin loader ($size: 1em, $thickness: 1px, $foreground: var(--black), $background: transparent, $speed: 1s) {
  @keyframes loader-spin {
    0% { transform: translate(-50%, -50%) rotate(0deg); }
    100% { transform: translate(-50%, -50%) rotate(360deg); }
  }

  position: absolute;
  top: 50%;
  left: 50%;

  width: $size;
  height: $size;

  border: $thickness solid $background;
  border-top: $thickness solid $foreground;
  border-left: $thickness solid $foreground;
  border-radius: 50%;

  animation: loader-spin $speed linear infinite;

  content: '';
  pointer-events: none;
}
