.card--member {
  border-radius: var(--border-radius);
  background: var(--color-gray-10);
  overflow: hidden;
  text-decoration: none;

  &__image {
    aspect-ratio: 1;
    padding:
      var(--spacing-0)
      var(--spacing-0)
      0;
    img {
      border-radius: 3px;
    }
  }

  &__name {
    @include font('meta');
    padding: var(--spacing-1);
    text-align: center;
    text-transform: unset;
  }

  // Fix Safari bug with eased text-decoration
  &:hover &__name {
    text-decoration: underline;
  }
}
